export enum LogLevel {
    debug = 'debug',
    info = 'info',
    warn = 'warn',
    error = 'error'
}

export class LogLevelConverter {
    public static from(level: string): LogLevel {
        switch (level) {
            case LogLevel.debug:
                return LogLevel.debug
            case LogLevel.info:
                return LogLevel.info
            case LogLevel.warn:
                return LogLevel.warn
            case LogLevel.error:
                return LogLevel.error
        }
    }
}

export class LogLevelPriority {
    public static priority(level: LogLevel): number {
        switch (level) {
            case LogLevel.debug:
                return 1
            case LogLevel.info:
                return 2
            case LogLevel.warn:
                return 3
            case LogLevel.error:
                return 4
        }
    }
}
