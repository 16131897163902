import { FrontEndType } from '../app/session/front-end-type'

export const environment = {
    production: false,
    baseUrl: '/api/',
    xApiKey: 'a1efb240-f8d8-40fe-a680-c3a5619a42e9',
    storage: 'localStorage',
    logLevel: 'debug',
    frontEndType: FrontEndType.backOffice,
    logModules: ['WebSessionService', 'PrematchTree', 'MatchUpdate']
}
