import { LogLevel, LogLevelConverter, LogLevelPriority } from './log-level'
import { LogDispatcher, Logger } from './logger'
import { environment } from '../../../environments/environment'
import { Injectable } from '@angular/core'

/**
 * Factory for class/module level loggers. It enables per class/module debug
 * using config file settings. Debugging can be event activated in production
 * for specific module
 *
 * <b>Usage:</b>
 *
 * In local.json config file add <i>debugModules</i> list of classes/modules for
 * which you want to enable debug.
 *
 * "logging": {
 *       ...,
 *       "debugModules": ["WorkerList"]
 *     }
 *
 * In module  WorkerList add to constructor:
 *
 *   this._logger = DebugLoggerFactory.createLogger('WorkerList');
 *
 *   and use this logger in two ways:
 *
 *   <b>Simple:</b>
 *
 *   this._logger.debug(\`Checking for timeouts for provider ${providerId}\`)
 *
 *   This call will make log only if module is in the  debugModules list
 *
 *   <b>With isActive check</b>
 *
 *    if (this._logger.isActive()) {
 *        this._logger.debug(`Event added to update check list ${JSON.stringify(xxx)}`)
 *    }
 *
 *    This check is used when we have to prepare log data, e.g. JSON.stringify() and
 *    we don't want this to be parsed in production code.
 *
 *
 */
@Injectable({
    providedIn: 'root'
})
export class LoggerFactory implements LogDispatcher {
    private readonly level: LogLevel
    private readonly levelPriority: number = LogLevelPriority.priority(LogLevel.info)
    private activeModules: string[]

    constructor() {
        this.activeModules = environment.logModules ? environment.logModules : []
        this.level = environment.logLevel ? LogLevelConverter.from(environment.logLevel) : LogLevel.error
        this.levelPriority = environment.logLevel
            ? LogLevelPriority.priority(LogLevelConverter.from(environment.logLevel))
            : LogLevelPriority.priority(LogLevel.info)
    }

    /**
     * Create logger for module name
     */
    createLogger(moduleName: string): Logger {
        return new Logger(this.isActive(moduleName), moduleName, this)
    }

    /**
     * Returns true if logging for module is active
     */
    private isActive(moduleName: string): boolean {
        return this.activeModules.includes(moduleName)
    }

    public log(level: LogLevel, message?: any, ...optionalParams: any[]) {
        if (LogLevelPriority.priority(level) >= this.levelPriority) {
            let args: any
            if (optionalParams.length > 0) {
                args = optionalParams.length === 1 ? optionalParams[0] : optionalParams
            }

            switch (level) {
                case LogLevel.debug:
                    if (message && args) {
                        console.debug(message, ...args)
                    } else if (message) {
                        console.debug(message)
                    } else {
                        console.debug(args)
                    }
                    break
                case LogLevel.info:
                    if (message && args) {
                        console.info(message, ...args)
                    } else if (message) {
                        console.info(message)
                    } else {
                        console.info(args)
                    }
                    break
                case LogLevel.warn:
                    if (message && args) {
                        console.warn(message, ...args)
                    } else if (message) {
                        console.warn(message)
                    } else {
                        console.warn(args)
                    }
                    break
                case LogLevel.error:
                    if (message && args) {
                        console.error(message, ...args)
                    } else if (message) {
                        console.error(message)
                    } else {
                        console.error(args)
                    }
                    break
            }
        }
    }
}
