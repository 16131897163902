import { Injectable } from '@angular/core'
import { SocketMessenger } from '../lib/web-socket/socket-messenger'
import { BehaviorSubject, combineLatest, Observable } from 'rxjs'
import { AuthService } from './auth.service'
import { MessageType, WebSocketAsyncMessage } from '../lib/web-socket/web-socket-async-message'
import { WebSocketAsyncMessageBuilder } from '../lib/web-socket/web-socket-async-message-builder'
import { LanguageService } from '../services/language.service'
import { WebSocketError } from '../lib/web-socket/web-socket-error'
import { LoggerFactory } from '../lib/logger/logger-factory'
import { Logger } from '../lib/logger/logger'
import { environment } from '../../environments/environment'
import { FrontEndType } from './front-end-type'

@Injectable({
    providedIn: 'root'
})
export class WebSessionService {
    private sessionRegisteredSubject: BehaviorSubject<boolean> = new BehaviorSubject(false)
    public sessionRegistered$: Observable<boolean> = this.sessionRegisteredSubject.asObservable()
    private readonly logger: Logger

    constructor(
        languageService: LanguageService,
        private authService: AuthService,
        private socketMessenger: SocketMessenger,
        loggerFactory: LoggerFactory
    ) {
        this.logger = loggerFactory.createLogger(WebSessionService.name)

        this.socketMessenger.onDisconnect$.subscribe(() => {
            this.sessionRegisteredSubject.next(false)
        })

        combineLatest([this.socketMessenger.onConnectionStatus$, this.authService.userSession$]).subscribe(
            ([onConnect, userSession]) => {
                if (onConnect && userSession) {
                    // For backoffice we dont make anonimous session registration
                    if (environment.frontEndType === FrontEndType.backOffice && userSession.isAnonymous) {
                        return
                    }

                    this.logger.debug('Sending "registerSession" request', userSession)
                    // When connected and with valid REST user session
                    // send request to register session over web socket
                    const message: WebSocketAsyncMessage = WebSocketAsyncMessageBuilder.builder()
                        .setMessageType(MessageType.request)
                        .setMessageName('registerSession')
                        .setPayload({
                            username: userSession.username,
                            sessionId: userSession.sessionId,
                            languageId: languageService.activeLanguage,
                            zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone
                        })
                        .build()
                    this.socketMessenger
                        .sendMessage<any>(message)
                        .then(() => {
                            this.logger.debug('Web socket session registered', userSession)
                            this.sessionRegisteredSubject.next(true)
                        })
                        .catch(err => {
                            if (err instanceof WebSocketError) {
                                this.logger.warn('Register session error', err.getFormattedMessage())
                                if (err.getStatusCode() !== 404) {
                                    // 404 Suggests that web domain is not valid
                                    // Either its injected or domain is not in the
                                    // "web domain" list on the backend side
                                    authService.logout(true)
                                }
                            }
                        })
                }
            }
        )
    }
}
