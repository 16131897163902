/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MwApiService } from '../mw-api-service';
import { MwApiConfig } from '../mw-api-config';
import { StrictHttpResponse } from '../strict-http-response';

import { CurrencyDto } from '../models/currency-dto';
import { getAgentCurrencyFilter } from '../fn/agent-currency-filter/get-agent-currency-filter';
import { GetAgentCurrencyFilter$Params } from '../fn/agent-currency-filter/get-agent-currency-filter';
import { updateAgentCurrencyFilter } from '../fn/agent-currency-filter/update-agent-currency-filter';
import { UpdateAgentCurrencyFilter$Params } from '../fn/agent-currency-filter/update-agent-currency-filter';

@Injectable({ providedIn: 'root' })
export class AgentCurrencyFilterMwApiService extends MwApiService {
  constructor(config: MwApiConfig, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateAgentCurrencyFilter()` */
  static readonly UpdateAgentCurrencyFilterPath = '/agent/currency/filter/{agentId}';

  /**
   * Update currency filter.
   *
   * Permission: 'agents-edit'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAgentCurrencyFilter()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAgentCurrencyFilter$Response(params: UpdateAgentCurrencyFilter$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CurrencyDto>>> {
    return updateAgentCurrencyFilter(this.http, this.rootUrl, params, context);
  }

  /**
   * Update currency filter.
   *
   * Permission: 'agents-edit'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAgentCurrencyFilter$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAgentCurrencyFilter(params: UpdateAgentCurrencyFilter$Params, context?: HttpContext): Observable<Array<CurrencyDto>> {
    return this.updateAgentCurrencyFilter$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CurrencyDto>>): Array<CurrencyDto> => r.body)
    );
  }

  /** Path part for operation `getAgentCurrencyFilter()` */
  static readonly GetAgentCurrencyFilterPath = '/agent/currency/filter/all/{agentId}';

  /**
   * Get currency filter.
   *
   * Permission: 'agents-view'
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAgentCurrencyFilter()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgentCurrencyFilter$Response(params: GetAgentCurrencyFilter$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CurrencyDto>>> {
    return getAgentCurrencyFilter(this.http, this.rootUrl, params, context);
  }

  /**
   * Get currency filter.
   *
   * Permission: 'agents-view'
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAgentCurrencyFilter$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgentCurrencyFilter(params: GetAgentCurrencyFilter$Params, context?: HttpContext): Observable<Array<CurrencyDto>> {
    return this.getAgentCurrencyFilter$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CurrencyDto>>): Array<CurrencyDto> => r.body)
    );
  }

}
