import { LogLevel } from './log-level'

export interface LogDispatcher {
    log(level: LogLevel, message?: any, ...optionalParams: any[]): void
}

export class Logger {
    private readonly isDebugActive: boolean
    private readonly moduleName: string
    private readonly logDispatcher: LogDispatcher

    /**
     * Setup of logger
     * @param isDebugActive {boolean} Is logging isDebugActive for this module
     * @param moduleName {string} Module name
     * @param logDispatcher {LogDispatcher} Log dispatcher
     */
    constructor(isDebugActive: boolean, moduleName: string, logDispatcher: LogDispatcher) {
        if (this.logDispatcher) throw new Error(`Logger for module ${moduleName} already configured`)
        this.logDispatcher = logDispatcher
        this.isDebugActive = isDebugActive
        this.moduleName = `[${moduleName}] `
    }

    /**
     * Ova metoda se koristi ako zelimo da pre logovanja ispitamo da li je uopste ukljucen debug
     * log za logger za slucajeve kada je priprema informacija za log zahtevnija pa da je izbegnemo
     * @returns {boolean}
     */
    public isDebug() {
        return this.isDebugActive
    }

    /**
     * Debug log
     */
    public debug(message?: any, ...optionalParams: any[]) {
        this.doLog(LogLevel.debug, message, optionalParams)
    }

    /**
     * Debug log
     */
    public info(message?: any, ...optionalParams: any[]) {
        this.doLog(LogLevel.info, message, optionalParams)
    }

    /**
     * Debug log
     */
    public warn(message?: any, ...optionalParams: any[]) {
        this.doLog(LogLevel.warn, message, optionalParams)
    }

    /**
     * Debug log
     */
    public error(message?: any, ...optionalParams: any[]) {
        this.doLog(LogLevel.error, message, optionalParams)
    }

    private doLog(logLevel: LogLevel, message?: any, optionalParams?: any[]) {
        if (this.isDebugActive || logLevel !== LogLevel.debug) {
            const msg = message ? this.moduleName + ' ' + message : this.moduleName
            this.logDispatcher.log(logLevel, msg, optionalParams)
        }
    }
}
